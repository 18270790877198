import React, { useState } from "react";
import { Button, Typography, Box, Grid } from "@mui/material";
import { Padding } from "@mui/icons-material";

interface OpenerButtonSx {
  [key: string]: string;
}

interface OpenerButtonStyle {
  variant?: 'text' | 'contained' | 'outlined';
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  display?: any;
  transition?: any;
  width?: any;
}

interface OpenerButtonProps {
  onClick: () => void;
  startIcon: any; 
  style?: OpenerButtonStyle;
  sxStyle?: OpenerButtonSx;
}

const OpenerButton: React.FC<OpenerButtonProps> = ({
  onClick,
  style,
  sxStyle,
  startIcon, 
}) => {
  return (
    <Button
      onClick={onClick}
      startIcon={startIcon}
      variant={style?.variant || "contained"}
      color={style?.color || "primary"}
      sx={{
        ...(sxStyle || {}),
      }}
    >
    </Button>
  );
};

export default OpenerButton;