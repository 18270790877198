import React from 'react';
import PopupChat from '../components/chat/popupChat';
import { useAppSelector, useAppDispatch } from '../lib/hook';
import { 
    chatSelector, 
    createAndFetchChats, 
    postAndFetchChats, 
    abortAndFetchChat, 
    RephraseMessage, 
    rephraseAndFetchChats, 
    mockAndFetchChats, 
    summarizeAndFetchChats,
    reviewAndFetchChats, 
} from '../lib/ducks/chatDuck';
import { authSelector } from '../lib/ducks/authDuck';

interface ComponentProps {
    //selectedId: string | null
}

const ChatView: React.FC<ComponentProps> = ({ }) => {
    const dispatch = useAppDispatch();
    const chatState = useAppSelector(chatSelector)
    const selectedId = useAppSelector(chatSelector).selectedChatId
    const user = useAppSelector(authSelector).user;
    const selectedPipe = useAppSelector(chatSelector).selectedPipe
    const roles = useAppSelector(authSelector).user?.roles
    const selectedRoleId = roles ? roles.find(role => role.name === selectedPipe)?.uuid : undefined;
    //const selectedRoleId = roles ? roles[selectedPipe] : undefined;

    const sendMessage = (message: string, exclude_ids: string[], sources_tables: string[]) => {
        if (selectedId) {
            dispatch(postAndFetchChats(selectedId, { message, exclude_ids, sources_tables, role_id: selectedRoleId }))
        } else {
            dispatch(createAndFetchChats({ message, exclude_ids, sources_tables, role_id: selectedRoleId }))
        }
    };

    const rephraseMessage = (message: string, sources_tables: string[]) => {
        const body = { message, sources_tables, role_id: selectedRoleId } as RephraseMessage
        if (selectedId) {
            dispatch(rephraseAndFetchChats(selectedId, body));
        } else {
            dispatch(rephraseAndFetchChats("", body));
        }
    }

    const summarizeDocuments = (documentIds: string[], type: string, message: string) => {
        if (selectedId) {
            dispatch(summarizeAndFetchChats(selectedId, documentIds, type, message));
        } else {
            dispatch(summarizeAndFetchChats("", documentIds, type, message));
        }
    }

    const reviewDocuments = (documentIds: string[], type: string) => {
        if (selectedId) {
            dispatch(reviewAndFetchChats(selectedId, documentIds, type));
        } else {
            dispatch(reviewAndFetchChats("", documentIds, type));
        }
    }

    const handleAbort = () => {
        if (selectedId) {
            dispatch(abortAndFetchChat(selectedId));
        }
    };

    const mockChat = (message: string = "Aloitetaan tutkimus. Miten käytän Codexia tehokkaimmin?") => {
        dispatch(mockAndFetchChats(message));
    }

    return (
        <PopupChat mockChat={mockChat} isLoading={chatState.messageIsLoading} messages={chatState?.selectedChat} sendMessage={sendMessage} handleAbort={handleAbort} rephraseMessage={rephraseMessage}
        summarizeDocuments={summarizeDocuments} reviewDocuments={reviewDocuments}/>
    )

};

export default ChatView;