import React from "react";
import { 
    Box,
    Button, 
    Typography, 
} from "@mui/material";
import pdfImage from "../assets/images/pdf.svg";
import docxImage from "../assets/images/docx.svg";
import { UserDocument } from "../views/fileManagamentView";

interface FileIconProps {
    name: string,
    height?: string,
    width?: string,
    useName?: boolean,
}

export const FileIcon: React.FC<FileIconProps> = ({ name, height = "64px", width = "64px" }) => { // remove name default value and fix mapping bubbles.tsx:82
    return (
        <Box sx={{ 
            display: "flex", 
            // mt: { xs: undefined, sm: "10px", }, 
            alignItems: "center", 
        }}>
            <img 
                src={name.endsWith("pdf") ? pdfImage : 
                    name.endsWith("docx") ? docxImage : ""}
                alt="file icon" 
                height={height} 
                width={width}
            />
                {/* <Typography sx={{ 
                    ml: { xs: undefined, sm: "10px", },
                    }}>{name}</Typography> */}
        </Box>
    )
}


interface StyledUserDocumentProps {
    file: UserDocument, 
    width: string, 
    height: string,
    handleDocumentClick?: (id: string) => void,
    useName?: boolean,
}

const StyledUserDocument: React.FC<StyledUserDocumentProps> = ({ file, handleDocumentClick, width, height, useName = true }) => {
    return (
        <Button 
        onClick={() => handleDocumentClick?.(file.id)}
        sx={{
            backgroundColor: "#F6F7FB", // move to theme
            width: { xs: undefined, sm: width, }, 
            height: { xs: undefined, sm: height, }, 
            display: "flex",
            flexDirection: { xs: undefined, sm: "column", }, 
            alignItems: { xs: undefined, sm: "center" },
            justifyContent: { xs: undefined, sm: "center" },
            borderRadius: "8px",
            overflow: "hidden",
            gap: "10px",   
        }}>
            <FileIcon name={file.name} />
            {useName === true && (
                <Typography sx={{
                    maxWidth: { xs: undefined, sm: "100%", }, 
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis' 
                }}>{file.name}</Typography>
            )}
        </Button>
    );
}

export default StyledUserDocument;