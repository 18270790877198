import React from "react";
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown';
import {
    IconButton,
} from "@mui/material";
import { Message } from "../../lib/ducks/chatDuck";

interface ComponentProps {
    handleSourceClick?: any;
    message: Message; 
}

export const ContentDialog: React.FC<ComponentProps> = ({ message, handleSourceClick }) => {
    return (
        <ReactMarkdown
            //linkTarget="_blank"
            //remarkPlugins={[remarkParse, remarkGfm, remarkRehype]}
            remarkPlugins={[remarkGfm]}
            //rehypePlugins={[rehypeStringify]}
            //rehypePlugins={[remarkRehype]}
            children={message.content.toString()}
            // children={'testi [^1](https://finlex.fi/fi/laki/ajantasa/1889/18890039001#L24P8)'}
            components={{
                // Map `h1` (`# heading`) to use `h2`s.
                // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                a(props) {
                    const { node, ...rest } = props
                    //console.log("em props", props)
                    if (rest.children && rest.children[0 as keyof typeof rest.children]
                        && typeof rest.children[0 as keyof typeof rest.children] === "string"
                        && ((String)(rest.children[0 as keyof typeof rest.children])).startsWith('^')) {
                        //console.log("found", (rest))
                        return (
                            <sup>
                                {message.response_type != "review" ? 
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ textDecoration: "none", backgroundColor: '#5870F026', borderRadius: '2px', padding: '0px 3px', color: 'black' }}
                                        {...rest}
                                    >
                                        {((String)(rest.children)).substring(1)}
                                    </a>
                                    : // stupid
                                    <IconButton onClick={() => handleSourceClick()} 
                                        sx={{
                                            margin: "0px",  
                                            fontSize: "12px", 
                                            backgroundColor: "#5870F026", 
                                            borderRadius: "2px",
                                            padding: '0px 3px',
                                            color: "black", 
                                        }}
                                    >
                                        {((String)(rest.children)).substring(1)}
                                    </IconButton>
                                    }
                            </sup>
                            )}
                                    return <a target="_blank" rel="noreferrer" {...rest} />
                                }
                            }}
                        />
                    )
}