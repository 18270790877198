
import React, { useState } from "react";
import { Container, Box, Paper, Stack, TextField, Grow, Typography, Button, Link } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { authSelector, login, setAlert } from "../lib/ducks/authDuck";
import { useAppDispatch, useAppSelector } from "../lib/hook";
import LoadingButton from '@mui/lab/LoadingButton';
import { LoginApi } from "../lib/apiActions";
import { useParams } from 'react-router-dom';
import AlertHandler from "../components/alertHandler";
import { useNavigate } from "react-router-dom";

const themeLight = createTheme({
    palette: {
        mode: 'light',
        bubbleColor: "grey.900",
        boxShade: '#0e0e0e99',
        responseBubbleColor: "grey.900",
        sidePanel: "#171526",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {

                root: {

                    borderRadius: '8px',
                    padding: '12px 24px',
                    textTransform: "none",
                },
                containedPrimary: {
                    backgroundColor: '#3A52D1',
                    //color: '#FFFFFF',
                },
                outlinedPrimary: {
                    color: '#545455',
                    borderColor: '#545455',
                },
                textPrimary: {
                    color: '#545455',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Puts the label into notched position
                    '.MuiInputLabel-outlined:has(+ .MuiOutlinedInput-root > .MuiOutlinedInput-input:-webkit-autofill)': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                    },
                    // Creates a hole around the text inside the border
                    '.MuiOutlinedInput-input:-webkit-autofill + .MuiOutlinedInput-notchedOutline > legend': {
                        maxWidth: '100%',
                    },
                },
            },
        },
    }
});

const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export default function ResetPasswordView(props: any) {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [reNewPassword, setReNewPassword] = useState('');
    const [errors, setErrors] = useState({ newPassword: false, reNewPassword: false })
    const [isLoading, setIsloading] = useState(false);
    const { token } = useParams();
    const dispacth = useAppDispatch();
    const navigate = useNavigate()

    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{12,}$/;

    const resetPress = async () => {
        setErrors({ newPassword: false, reNewPassword: false })
        if (!passwordRegex.test(password)) {
            setErrors(o => ({ ...o, newPassword: true }))
            return
        }

        if (password != reNewPassword) {
            setErrors(o => ({ ...o, reNewPassword: true }))
            return
        }

        const body = {
            email: username,
            password: password,
            token
        }
        setIsloading(true);
        const result = await LoginApi.resetPassword(body, { validateStatus: false });
        if (result.status < 300) {
            dispacth(setAlert({ message: 'Salasanan vaihto onnistui', type: 'success', key: (new Date()).toString() }))
            await sleep(3000)
            navigate("/login")
        } else {
            dispacth(setAlert({ message: 'Salasanan vaihto epäonnistui', type: 'error', key: (new Date()).toString() }))
        }
        setIsloading(false);
    }

    return (
        <ThemeProvider theme={themeLight}>
            <CssBaseline />
            <Box sx={{ display: "flex", flexDirection: 'row', height: '100vh' }}>
                <Box sx={{
                    flex: 1, minWidth: '70%',
                    display: { xs: "none", sm: 'flex' }, 
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#CFD4EA'
                }}>
                    <img
                        src="/images/login.gif"
                        height={'auto'}
                        width='100%'
                    />
                </Box>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column', alignItems: 'center',
                    padding: '48px'
                }}>
                    <Box
                        width={'100%'}
                        alignItems={'left'}
                    >
                        <img
                            src="/images/codex_logo_black.png"
                            height={'auto'}
                            width='137px'
                        />

                    </Box>

                    <Stack
                        marginTop={'124px'}
                        gap={'24px'}
                        width={'100%'}
                    >
                        <Typography variant="h6" gutterBottom align="center">
                            Palauta salasana
                        </Typography>
                        <TextField fullWidth label="Käyttäjätunnus"
                            value={username}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUsername(event.target.value);
                            }}
                        />
                        <TextField fullWidth label="Uusi salasana" type="password"
                            value={password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPassword(event.target.value);
                            }}
                            helperText={errors.newPassword ? "Salasanan tulee olla vähintään 12 merkkiä pitkä ja sisältää vähintään yksi iso kirjain, yksi pieni kirjain ja yksi numero" : ""}
                            error={errors.newPassword}
                        />

                        <TextField fullWidth label="Uusi salasana uudestaan" type="password"
                            value={reNewPassword}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setReNewPassword(event.target.value);
                            }}
                            helperText={errors.reNewPassword ? "Salasanat eivät täsmää" : ""}
                            error={errors.reNewPassword}
                        />
                        <Typography variant="h6">
                            Asettamalla uuden salasanan, hyväksyt sovelluksen <Link href="https://www.codexplus.fi/kayttoehdot" target="_blank">käyttöehdot</Link>.
                        </Typography>
                        <LoadingButton loading={isLoading} onClick={resetPress} variant="contained">Palauta</LoadingButton>
                        {
                        /*
                            < Box display={"flex"} flexDirection={'row'} justifyContent='right' flexWrap='wrap'>
                        <Button variant="text">Unohdin salasanan</Button>
                        <Button variant="text">Pyydä Tunnuksia</Button>
                    </Box>
                        */}
                    </Stack>
                </Box>
            </Box>
            <AlertHandler />
        </ThemeProvider>
    )
}