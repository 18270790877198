import React, { useState } from 'react';
import {
    Container,
    Box,
    Typography,
    Card,
    Button,
    CardContent
} from '@mui/material'
import { authSelector } from '../lib/ducks/authDuck';
import { useAppSelector } from '../lib/hook';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CustomerContactFormDialog from './CustomerContact/CustomerContactFormDialog';
import useCheckMobileScreen from '../utils/useCheckMobileScreen';
import Tooltip from '@mui/material/Tooltip';


interface ComponentProps {
    userName: string | undefined;
    mockChat: any;
    selectedFiles: any;
}

interface CardProps {
    header: string;
    text: string;
    icon: any;
    buttonIcon?: any;
    buttonText: string;
    buttonDisabled: boolean;
    onClick?: any;
    isMobile: boolean;
    tooltipTitle?: string;
}

const StyledCard: React.FC<CardProps> = (props) => {

    return (
        <Box
            component={props.isMobile ? "button" : "div"}
            onClick={props.isMobile ? props.onClick : undefined}
            sx={{
                maxWidth: { xs: "265px", sm: '320px' },
                maxHeight: { xs: "48px", sm: '236px' },
                padding: { xs: "12px 13px", sm: '24px 32px'},
                margin: '16px',
                backgroundColor: '#F8F8F8',
                textAlign: 'center',
                border: '1px',
                borderColor: '#E6E6E9',
                borderRadius: '8px',
                background: '#F8F8F8',
                borderStyle: 'solid',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: "center",
                color: "#16171B"

            }}
        >
            {!props.isMobile && props.icon}
            <Typography variant="h3" component="div" fontWeight={'bold'} lineHeight={'19px'}
                sx={{
                    fontSize: { xs: '16px', sm: '18px' },
                    margin: { xs: "8px 0px", sm: "12px 0px" },
                    display: { xs: "flex", sm: undefined},
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {props.isMobile && (
                    <Box sx={{mr: "6px"}}>
                        {props.icon}
                    </Box>
                )}
                {props.header}
            </Typography>
            {!props.isMobile && (
                <>
                    <Typography variant="body2" fontSize={'16px'} lineHeight={'19px'}>
                        {props.text}
                    </Typography>
                    <Tooltip title={props.buttonDisabled ? props.tooltipTitle : ""}>
                        <span>
                            <Button
                                disabled={props.buttonDisabled}
                                variant="contained"
                                sx={{ color: '#FFFFFF', backgroundColor: '#5870F0', mt: { xs: "inherit", sm: "16px"} }}
                                endIcon={props.buttonIcon}
                                onClick={props.onClick}
                            >
                                {props.buttonText}
                            </Button>
                        </span>
                    </Tooltip>
                </>
            )}
        </Box>
    );
}

export const WelcomeMessage: React.FC<ComponentProps> = ({ userName, mockChat, selectedFiles }) => {
    const darkTheme = useAppSelector(authSelector).darkTheme;
    const user = useAppSelector(authSelector).user;
    const [isFormOpen, setFormOpen] = useState(false);
    const isMobile = useCheckMobileScreen();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
                
            >
                <img
                    src={darkTheme ? "/images/codex_logo_full.webp" : "/images/codex_logo_black.png"}
                    height={'auto'}
                    width={'200px'}
                />

                <Typography fontSize={'18px'} fontWeight={'bold'} variant="h6" align='center' 
                sx={{ 
                    marginTop: '24px', 
                    marginBottom: '0px',
                }} >
                    {!isMobile ? "Onneksi olkoon, olet saanut Codexin käsiisi!" : ""}
                </Typography>
                <Typography fontSize={'18px'} variant="h5" sx={{ marginTop: '0px' }} align='center'>
                    {user?.type === "entrepreneur" && !isMobile ? 
                        "Voit nyt itse tehdä esianalyysin juridisista kysymyksistä ja tarvittaessa ohjata asian asiantuntijalle jatkokäsittelyyn – helposti ja nopeasti." 
                        : !isMobile ? "Tekoälyllä buustattua juristia vastaan on vaikea kilpailla perinteisillä menetelmillä."
                        : null
                    }
                </Typography>
                <Typography variant="h6" fontSize={'16px'} fontWeight={'bold'} sx={{ marginTop: '24px' }} gutterBottom>
                    Hei {user?.first_name}, mitä haluaisit tehdä tänään?
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: { xs: "wrap", sm: 'nowrap' },
                    flexDirection: "row",
                    justifyContent: "center",
                    justifyItems: "center",
                    p: 1,
                    m: 1,
                    marginTop: '10px',
                    maxWidth: { xs: "100vw", sm: '1024px' },
                }}
                // width={'100%'}
                // maxWidth={'1024px'}

            >

                <StyledCard
                    header="Tutkimus"
                    text="Hanki vastauksia laeista, oikeustapauksista, säädöksistä jne."
                    icon={<DescriptionIcon style={{ color: "#5870F0" }} />}
                    buttonText='Aloita tutkimus'
                    buttonIcon={<ArrowForwardIcon />}
                    buttonDisabled={false}
                    onClick={() => mockChat("Aloitetaan tutkimus. Miten käytän Codexia tehokkaimmin?")}
                    isMobile={isMobile}
                />
                <StyledCard
                    header="Yhteenveto"
                    text="Tiivistä tärkeä tieto mistä tahansa asiakirjasta."
                    icon={<DocumentScannerIcon style={{ color: "#5870F0" }} />}
                    buttonText='Tiivistä asiakirja'
                    buttonIcon={<FileUploadIcon />}
                    buttonDisabled={selectedFiles.length === 0}
                    isMobile={isMobile}
                    onClick={() => mockChat("Aloitetaan yhteenveto.")}
                    tooltipTitle='Valitse ainakin yksi tiedosto'
                />
                { user?.type === "entrepreneur" && (
                    <StyledCard
                        header="Juristin yhteydenotto"
                        text="Löydä juristi vaivattomasti Agoran alustan kautta"
                        icon={<ContactPhoneIcon style={{ color: "#5870F0" }} />}
                        buttonText="Yhteydenotto"
                        buttonIcon={<ArrowForwardIcon />}
                        buttonDisabled={false}
                        onClick={setFormOpen}
                        isMobile={isMobile}
                    />
                )}

                <CustomerContactFormDialog
                    isOpen={isFormOpen}
                    handleClickClose={() => setFormOpen(false)}
                    user={user}
                />


            </Box>
        </Box>
    );
};