import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../lib/hook";
import { authSelector, changePassword, deleteUserAndLogout, updateUserAndFetch, User, UserInfo } from "../lib/ducks/authDuck";
import StyledAvatar from "../components/avatar";
import { useAvatarDialog } from "../components/dialogs/AvatarDialog";
import useCheckMobileScreen from "../utils/useCheckMobileScreen";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

interface RemoveUserComponentProps {
    onRemoveUser: any
}


const RemoveUserView: React.FC<RemoveUserComponentProps> = ({ onRemoveUser }) => {
    const [state, setState] = useState(false);
    return (
        <>
            <Box sx={{ 
                // backgroundColor: "red",
                display: "flex",
                flexDirection: "column", 
                width: { xs: "90vw", sm: "initial" },
            }}
            >
                <Box>
                    <Typography sx={{ fontWeight: "bold" }}>Poista käyttäjä?</Typography>
                </Box>
                <Box
                    display={'flex'}
                    flex={1}
                    justifyContent={'space-between'}
                    alignContent={'center'}
                >
                    <FormControlLabel required control={<Checkbox value={state} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setState(event.target.checked);
                    }} />} label="Vahvistan tilin poistamisen" />
                </Box>
                <Box>

                    <Button
                        variant='contained'
                        color="error"
                        sx={{
                            backgroundColor: '#FF999C'
                        }}
                        disabled={!state}
                        onClick={onRemoveUser}
                    >
                        Poista käyttäjä
                    </Button>
                </Box>
            </Box>
        </>

    );
}

interface ComponentProps {
    user: UserInfo | null
    setUser: any
    onUserSave: any
    onSetUserDefaults: any
    onSetDefaultAvatar: any
}


const UserInfoView: React.FC<ComponentProps> = ({ user, setUser, onUserSave, onSetUserDefaults, onSetDefaultAvatar }) => {
    const saveEnabled = user?.last_name != '' && user?.first_name != ''
    const isMobile = useCheckMobileScreen();
    const [getForm, AvatarDialog] = useAvatarDialog();

    const onChangeAvatar = async () => {
        console.log("avatarName", user?.avatar_name)
        const { status, avatarName } = await getForm({ nameNeeded: false, avatarName: user?.avatar_name })

        console.log(status)
        if (status)
            setUser({ ...user, avatar_name: avatarName })
    }

    return (
        <>
            <AvatarDialog />
            <Box
                display={'flex'}
                flex={1}
                flexDirection={'row'}
                marginBottom={2}
                sx={{
                    margin: "10px", 
                }}
            >
                <StyledAvatar avatarName={user?.avatar_name} size={100} 
                sx={{
                    borderRadius: { xs: "8px", sm: "50%" }, 
                }} 
                />
                <Box
                    sx={{ 
                        // tarkasta nää ennen commit
                        width: { xs: "100vw", sm: "initial" }, 
                        height: { xs: "initial", sm: "initial" }, 
                        // backgroundColor: "grey",
                        display: "flex",
                        flex: { xs: "initial", sm: 1 }, 
                        flexDirection: "column", 
                        justifyContent: "center", 
                        ml: { xs: 0, sm: 2 },
                    }}
                >
                    <Box sx={{
                        display: { xs: "flex", sm: "initial" },
                        justifyContent: { xs: "center", sm: "flex-start" }, 
                        alignItems: { xs: "center", sm: "initial" }, 
                        flexDirection: { xs: "column", sm: "row" }, 
                        mb: { xs: 0, sm: 1 }, 
                    }}
                    >
                        <Button
                            variant="contained"
                            onClick={onChangeAvatar}
                            sx={{
                                fontSize: { xs: "13px", sm: "initial" }, 
                                width: { xs: "40vw", sm: "initial" }, 
                                height: { xs: "35px", sm: "initial" }, 
                            }}
                        >
                            Vaihda kuvake</Button>
                        <Button
                            variant="outlined"
                            onClick={onSetDefaultAvatar}
                            sx={{
                                backgroundColor: 'transparent',
                                color: '#F93A37',
                                borderColor: '#F93A37',
                                marginLeft: { xs: 0, sm: 2 },
                                fontSize: { xs: "13px", sm: "initial" }, 
                                width: { xs: "40vw", sm: "initial" }, 
                                height: { xs: "35px", sm: "initial" }, 
                                mt: { xs: 1, sm: 0 },
                            }}
                        >Nollaa kuvake</Button>
                    </Box>
                    {!isMobile && (
                        <Typography fontWeight={10}>Valitse itsellesi kuvake</Typography>
                    )}
                </Box>

            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" }, 
                    flex: { xs: undefined, sm: 1 },
                    justifyContent: { xs: undefined, sm: "space-between" },
                    alignContent: { xs: undefined, sm: "space-between" },
                    alignItems: { xs: "center", sm: "initial" }, 
                    // overflow: "auto", 
                }}
            >
                <TextField
                    label='Etunimi'
                    value={user?.first_name}
                    required={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, first_name: event.target.value })}
                    sx={{ width: { xs: "90vw", sm: '460px' }, mt: 3, }}
                />
                <TextField
                    label='Sukunimi'
                    value={user?.last_name}
                    required={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, last_name: event.target.value })}
                    sx={{ width: { xs: "90vw", sm: '460px' }, mt: 3, }}
                />
            </Box>
            <Box
                display={'flex'}
                flex={1}
                justifyContent={'space-between'}
                alignContent={'space-between'}
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "center", sm: "initial" }, 
                }}
            >
                <TextField
                    value={user?.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, email: event.target.value })}
                    disabled={true}
                    label='Sähköposti'
                    sx={{ width: { xs: "90vw", sm: '460px' }, mt: 3 }}
                />
                <TextField
                    value={user?.organization}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, organization: event.target.value })}
                    label='Organisaatio'
                    sx={{ width: { xs: "90vw", sm: '460px' }, mt: 3 }}
                />
            </Box>
            <Box
                // display={'flex'}
                // flex={1}
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "center", sm: "initial" }, 
                }}
            >
                <TextField
                    value={user?.phone_number}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, phone_number: event.target.value })}
                    label='Puhelin'
                    sx={{ width: {xs: "90vw", sm: '460px'}, mt: 3, mb: 3, }}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "center", sm: "initial" }, 
                }}>
                <Button
                    variant='contained'
                    sx={{ 
                        mr: { xs: "0", sm: '16px' },
                        width: { xs: "90vw", sm: 'initial' }, 
                    }}
                    onClick={onUserSave}
                    disabled={!saveEnabled}
                >
                    Tallenna muutokset
                </Button>
                <Button
                    variant='outlined'
                    onClick={onSetUserDefaults}
                    sx={{ 
                        mt: { xs: 1, sm: 0 },
                        mr: { xs: 0, sm: '16px' }, 
                        mb: { xs: 2, sm: 0 }, 
                        width: { xs: "90vw", sm: 'initial' }, 
                    }}
                >
                    Nollaa muutokset
                </Button>
            </Box>
        </>
    );
}

interface ChangePasswordComponentProps {
    onChangePassword: any
}

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{12,}$/;
const ChangePasswordView: React.FC<ChangePasswordComponentProps> = ({ onChangePassword }) => {

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [errors, setErrors] = useState({ newPassword: false, newPassword2: false })

    const handleChangePassword = () => {
        if (!passwordRegex.test(newPassword)) {
            setErrors(o => ({ ...o, newPassword: true }))
            return
        }
        onChangePassword(oldPassword, newPassword);
    }

    return (
        <>
            <Box sx={{
                display: "flex",
                justifyContent: "flex-start", 
                width: { xs: "90vw", sm: "initial" },
                ml: { xs: "auto", sm: "initial" },
                mr: { xs: "auto", sm: "initial" },
            }}>
                <Typography sx={{
                    display: { xs: "flex", sm: "none" }, 
                    fontWeight: "bold",
                    fontSize: "18px", 
                }}>
                    Vaihda salasanaa
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" }, 
                    justifyContent: { xs: undefined, sm: "space-between" },
                    alignContent: { xs: undefined, sm: "space-between" },
                    alignItems: { xs: "center", sm: "initial" }, 
                    flex: { xs: undefined, sm: 1 },
                }}

            >
                <TextField
                    type="password"
                    label='Nykyinen salasana'
                    value={oldPassword}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOldPassword(event.target.value)}
                    sx={{ width: { xs: "90vw", sm: '460px' }, mt: 2, }}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignContent: { xs: "initial", sm: "space-between" },
                    alignItems: { xs: "center", sm: "initial" },
                    flex: { xs: undefined, sm: 1 },
                }}
            >
                <TextField
                    type="password"
                    label='Uusi salasana'
                    value={newPassword}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
                    sx={{ width: { xs: "90vw", sm: '460px' }, mt: 2, }}
                    error={errors.newPassword}
                    helperText={errors.newPassword ? "Tarkista salasanan vaatimukset" : ""}
                />
                <TextField
                    type="password"
                    label='Vahvista uusi salasana'
                    value={newPassword2}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewPassword2(event.target.value)}
                    sx={{ width: { xs: "90vw", sm: '460px' }, mt: 2, }}
                    error={errors.newPassword2}
                    helperText={errors.newPassword2 ? "Salasanat eivät täsmää" : ""}
                />
            </Box>
            <Box sx={{
                width: { xs: "90vw", sm: "initial" }, 
                ml: { xs: "auto", sm: "initial" },
                mr: { xs: "auto", sm: "initial" },
            }}>
                <Typography sx={{ fontSize: { xs: "15px", sm: "initial" }, }}>
                    Salasanavaatimukset:
                </Typography>
                <Typography variant="caption" display="block">
                    - Vähintään 12 merkkiä pitkä
                </Typography>
                <Typography variant="caption" display="block">
                    - Vähintään yksi iso kirjain, yksi pieni kirjain ja yksi numero.
                </Typography>
                <Typography variant="caption" display="block">
                    - Ei saa olla yleisesti käytettyjen salasanojen joukossa.
                </Typography>
                <Typography variant="caption" display="block">
                    - Ei saa olla viittauksia käyttäjän tietoihin.
                </Typography>
            </Box>
            <Box sx={{
                width: { xs: "90vw", sm: "initial" }, 
                display: "flex", 
                justifyContent: "center",
                mr: { xs: "auto", sm: "initial" },
                ml: { xs: "auto", sm: "initial" }, 
            }}>
                <Button
                    variant='contained'
                    sx={{ 
                        marginRight: { xs: 0, sm: '16px' },
                        width: { xs: "90vw", sm: 'initial' }, 
                    }}
                    onClick={handleChangePassword}
                    disabled={newPassword != newPassword2 || newPassword === "" || oldPassword === ""}
                >
                    Vaihda salasana
                </Button>
            </Box>
        </>
    );

}

export const ProfileView = () => {
    const userRedux = useAppSelector(authSelector).user
    const [user, setUser] = useState<UserInfo | null>(userRedux);
    const [userViewSelected, setUserViewSelected] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        onSetUserDefaults();
    }, [userRedux])

    const onUserSave = () => {
        console.log("user", user)
        if (user)
            dispatch(updateUserAndFetch(user));
    }
    const onRemoveUser = () => {
        dispatch(deleteUserAndLogout())
    }
    const onSetUserDefaults = () => {
        if (userRedux) {
            const newObject = {
                first_name: userRedux.first_name,
                last_name: userRedux.last_name,
                organization: userRedux.organization,
                email: userRedux.email,
                phone_number: userRedux.phone_number,
                avatar_name: userRedux.avatar_name
            }
            setUser(newObject as UserInfo)
        }
    }

    const onSetDefaultAvatar = () => {
        if (userRedux && user != null) {
            setUser({ ...user, avatar_name: userRedux.avatar_name })
        }
    }

    const onChangePassword = (oldPassword: string, newPassword: string) => {
        dispatch(changePassword({ old_password: oldPassword, password: newPassword }))
    }

    return (
        <Box
            sx={{
                maxWidth: { xs: "100vw", sm: "1011px" }, 
                height: { xs: "100vh", sm: "initial" },
                display: "flex",
                justifyContent: { xs: "flex-start", sm: "initial" },
                flexDirection: "column",
                flex: 1, 
                mt: { xs: "15px", sm: undefined, }, 
            }}
        >
            {userRedux?.first_name === "" &&
                <Typography paddingBottom={2}>Ennen kuin aloitat, täytä alla olevat tiedot.</Typography>
            }
            <Box sx={{
                display: { xs: "flex", sm: "initial" },
                flexDirection: { xs: "row", sm: "initial" },
                justifyContent: { xs: "center", sm: "initial" },
                width: { xs: "90vw", sm: "initial" },
                margin: { xs: "auto", sm: "initial" }, 
            }}>
                <Button
                    variant={userViewSelected ? 'contained' : 'text'}
                    sx={{ 
                        marginRight: { xs: "initial", sm: '16px' },
                        width: { xs: "35vw", sm: "initial" }, 
                    }}
                    onClick={() => setUserViewSelected(true)}
                >
                    <PersonIcon sx={{ mr: { xs: 1, sm: "initial" }}} />
                    Tili
                </Button>
                <Button
                    variant={!userViewSelected ? 'contained' : 'text'}
                    onClick={() => setUserViewSelected(false)}
                    sx={{
                        width: { xs: "65vw", sm: "initial" },
                    }}
                >
                    <LockIcon sx={{ mr: { xs: 1, sm: "initial" }}}/>
                    Turvallisuus
                </Button>
            </Box>
            <Box
                marginTop={'16px'}
                display="flex"
                //justifyContent="center"
                flexDirection={'column'}
                flex={1}
                sx={{
                    backgroundColor: '#FCFCFC', 
                    borderStyle: 'solid', 
                    border: 1, 
                    borderRadius: '8px', 
                    borderColor: '#E6E6E9',
                    maxWidth: { xs: "100vw", sm: "1011px" },
                    maxHeight: { xs: "100vh", sm: "490px" },
                    padding: { xs: "0px", sm: '24px 32px'},
                    margin: { xs: "10px", sm: "initial" }, 
                    height: { xs: "100vh", sm: "initial" }, 
                }}
            >
                {userViewSelected ?
                    <UserInfoView user={user} setUser={setUser} onUserSave={onUserSave} onSetUserDefaults={onSetUserDefaults} onSetDefaultAvatar={onSetDefaultAvatar} />
                    :
                    <ChangePasswordView onChangePassword={onChangePassword} />
                }

            </Box>

            {userViewSelected &&
                <Box
                    marginTop={'16px'}
                    display="flex"
                    //justifyContent="center"
                    flexDirection={'column'}
                    flex={1}
                    padding={'24px 32px'}
                    sx={{
                        backgroundColor: '#FCFCFC', 
                        borderStyle: 'solid', 
                        border: 1, 
                        borderRadius: '8px', 
                        borderColor: '#E6E6E9',
                        maxWidth: { xs: "100vw", sm: "1011px" },
                        maxHeight: { xs: "100vh", sm: "190px" }, 
                        padding: { xs: "12px 13px", sm: '24px 32px'}, 
                        margin: { xs: "10px", sm: "20px 0px" },
                    }}
                >

                    <RemoveUserView onRemoveUser={onRemoveUser} />


                </Box>
            }

        </Box>
    );
}