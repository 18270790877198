import React, { useState } from 'react';
import { EditNote } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ListIcon from '@mui/icons-material/List';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LogoutIcon from '@mui/icons-material/Logout';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import {
    Box, 
    IconButton,
    CSSObject,
    Theme,
    styled,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Link
} from "@mui/material";

import { authSelector } from '../../lib/ducks/authDuck';
import { useAppSelector, useAppDispatch } from '../../lib/hook';
import { useNavigate, Navigate } from 'react-router-dom';
import useConfirm from "../dialogs/ConfirmDialog";
import { useFormDialog } from "../dialogs/FormDialog";
import { ChatDrawer } from '../../components/ChatDrawer';
import { requestLogoutAllAndLogout } from '../../lib/ducks/authDuck';
import StyledAvatar from "../avatar";

interface ComponentProps {
    drawerWidthMin: number,
    drawerWidthMax: number,
    // leftDrawerOpen: boolean | undefined,
    handleNewChat: any,
    handleDrawerOpen: any,
    handleDrawerClose: any,
    handleFeedback: any, 
    handleProfile?: any,
    handleSearch?: any,
    handleListingTool?: any,
    handleSettings?: any,
    handleFileManager?: any,
    componentOpen: boolean,
}

interface MyDrawerProps extends DrawerProps {
    drawerwidthmin: number,
    drawerwidthmax: number,
    drawerWidth: number
}

interface ChatDrawerProps {
    navigationDrawerWidthMin: number,
    drawerWidth: number
    handleNewChat: any,
    handleDrawerOpen: any,
    handleDrawerClose: any,
    handleChatChange: any
}

const OpenedMixin = (theme: Theme, drawerWidthMax: number): CSSObject => ({
    width: drawerWidthMax,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    border: "none"
});

const closedMixin = (theme: Theme, drawerwidthmin: number): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: drawerwidthmin,
    border: "none"
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<MyDrawerProps>(
    ({ theme, open, drawerwidthmin, drawerwidthmax }) => ({
        width: drawerwidthmax,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...OpenedMixin(theme, drawerwidthmax),
            '& .MuiDrawer-paper': OpenedMixin(theme, drawerwidthmax),
        }),
        ...(!open && {
            ...closedMixin(theme, drawerwidthmin),
            '& .MuiDrawer-paper': closedMixin(theme, drawerwidthmin),
        }),
    }),
);

export const NavigationDrawerMobile: React.FC<ComponentProps & ChatDrawerProps> = (props) => {
    // const navigate = useNavigate()
    // const [navigationDrawerOpen, setNavigationDrawerOpen] = useState(false);
    const [getConfirmation, ConfirmationDialog] = useConfirm();
    const dispatch = useAppDispatch();
    const darkTheme = useAppSelector(authSelector).darkTheme;
    const user = useAppSelector(authSelector).user;
    const navigate = useNavigate();
    const [anchorElSettings, setAnchorElSettings] = React.useState<null | HTMLElement>(null);
    const settingsOpen = Boolean(anchorElSettings);
    const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElSettings(event.currentTarget);
    };
    const handleCloseSettings = () => {
        setAnchorElSettings(null);
    };

    const onLogout = async () => {
        const response = await getConfirmation({ title: 'Haluatko varmasti kirjautua ulos?', text: '', okText: 'Kyllä', cancelText: 'Peruuta', buttonColorWarning: false })
        if (response) {
            dispatch(requestLogoutAllAndLogout())
        }
    }
    

    return (
        <>
        <ConfirmationDialog />
        <Drawer drawerwidthmax={props.drawerWidthMax} drawerwidthmin={props.drawerWidthMin} variant="permanent"
            open={props.componentOpen} drawerWidth={props.drawerWidthMax} onClose={props.handleDrawerClose}
            ModalProps={{
                keepMounted: true, 
            }}
            sx={{
                '& .MuiDrawer-paper': {
                    minWidth: '100vw',
                    minHeight: '100vh',
                    position: 'fixed',
                    zIndex: 1100,
                    color: "#F8F8F8",
                    transition: "transform 0.3s ease-out, visibility 0.3s ease",
                    visibility: props.componentOpen ? "visible" : "hidden", 
                    transform: props.componentOpen ? "translateX(0)" : "translateX(-100%)", 
                },
            }}
            PaperProps={{className: "navigationBarDrawerMobile"}}
            >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100vw",
                    height: "100vh",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "64px",
                        // backgroundColor: "red",
                    }}
                >
                    {/* topbar, close menu, new chat, search? */}
                    <Box>
                        <IconButton
                            color="inherit"
                            aria-label="close menu"
                            onClick={props.handleDrawerClose}
                            edge="start"
                            sx={{ ml: 2,
                                ...(props.componentOpen && { display: "flex", 
                                    order: 1,
                            })}}
                            >
                                <MenuIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{
                        display: "flex",
                        alignItems: "center", 
                    }}>
                        <IconButton
                            color="inherit"
                            aria-label="new chat"
                            onClick={() => { props.handleNewChat(); props.handleDrawerClose();}}
                            edge="end"
                            sx={{ mr: 2, ml: 2,  
                                ...(props.componentOpen && { display: "flex", 
                                    order: 3,
                            })}}
                            >
                                <EditNote />
                        </IconButton>

                        <MonetizationOnIcon sx={{mr: 1}}/> {user?.query_quota}

                        {/* <IconButton
                            color="inherit"
                            aria-label="search"
                            onClick={props.handleSearch}
                            edge="end"
                            sx={{ mr: 2,
                                ...(props.open && { display: "flex", 
                                    order: "3",
                            })}}
                            >
                                <SearchIcon />
                        </IconButton> */}
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        // backgroundColor: "red",
                        height: "168px",
                        width: "100vw",
                        pt: "4vh",
                        pl: "8vw",
                    }}>
                    <img
                        src={darkTheme ? "/images/codex_logo_white.png" : "/images/codex_logo_white.png"} // both white logos 
                        height={'auto'}
                        width={'72.13px'}
                        onClick={() => { props.handleNewChat(); props.handleDrawerClose(); navigate("/"); }}
                    />

                    <IconButton
                        color="inherit"
                        aria-label="listing tool"
                        onClick={() => { props.handleDrawerClose(); navigate("/search"); }}
                        edge="start"
                        sx={{ fontSize: "14px", pt: "3vh", color: "#FFFFFF", 
                            ...(props.componentOpen && { display: "flex", 
                                order: "1",
                        })}}
                        >
                            <ListIcon /> Listaustyökalu
                    </IconButton>

                    <IconButton
                        color="inherit"
                        aria-label="file management"
                        onClick={() => { props.handleDrawerClose(); navigate("/filemanagement"); }}
                        edge="start"
                        sx={{ fontSize: "14px", pt: "2vh", color: "#FFFFFF", 
                            ...(props.componentOpen && { display: "flex", 
                                order: "2",
                        })}}
                        >
                            <FolderIcon /> Tiedostonhallinta
                    </IconButton>

                    {user?.is_staff &&
                        <IconButton
                            color="inherit"
                            aria-label="staff"
                            edge="start"
                            onClick={() => { props.handleDrawerClose(); navigate("/staff"); }}
                            sx={{ fontSize: "14px", pt: "2vh", color: "#FFFFFF", 
                                ...(props.componentOpen && { display: "flex", 
                                    order: "2",
                            })}}
                            >
                                <SupervisorAccountIcon /> Käyttäjät

                        </IconButton>
                    }
                    
                </Box>

                <Box
                    sx={{
                        minWidth: "100vw",
                        height: "40vh",
                        maxHeight: "40vh",
                        display: "flex",
                        flexDirection: "column",
                        // backgroundColor: "blue",
                        position: "relative",
                        ml: "2vw",
                        mt: "auto",
                    }}>
                    <ChatDrawer
                        handleChatChange={props.handleChatChange}
                        handleDrawerClose={props.handleDrawerClose}
                        handleDrawerOpen={props.handleDrawerOpen}
                        handleNewChat={props.handleNewChat}
                        drawerWidth={props.drawerWidth}
                        navigationDrawerWidthMin={props.navigationDrawerWidthMin}
                        open={true}
                    />
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        // backgroundColor: "red",
                        minWidth: "100vw",
                        mt: "auto",
                        mb: { xs: "10vh", sm: 0, }, // temp fix for mobile browser controls covering profile and settings 
                    }}>
                    <IconButton
                        onClick={() => { props.handleDrawerClose(); navigate("/profile"); }} 
                        sx={{ 
                            marginLeft: '2vw', 
                            order: 1 
                        }}>
                        <StyledAvatar avatarName={user?.avatar_name} size={42} sx={{}} />
                    </IconButton>

                    <Typography sx={{ 
                        fontSize: "15px", 
                        color: "#FFFFFF", 
                        mt: "auto", 
                        mb: "auto", 
                        order: 1
                    }}>
                        {`${user?.first_name} ${user?.last_name}`}
                    </Typography>

                    <IconButton
                        color="inherit"
                        aria-label="settings"
                        onClick={handleClickSettings}
                        edge="end"
                        sx={{ ml: "auto", pr: "10vw",
                            ...(props.componentOpen && { display: "flex", 
                                order: 3,
                        })}}
                        >
                            <SettingsIcon />
                    </IconButton>
                    <Menu
                        aria-label="settings-menu"
                        anchorEl={anchorElSettings}
                        open={settingsOpen}
                        onClose={handleCloseSettings}
                    >
                        <MenuItem onClick={() => { handleCloseSettings(); props.handleDrawerClose(); navigate("/info"); }}>
                            <ListItemIcon>
                                <HelpIcon/>
                            </ListItemIcon>
                            <ListItemText>Ohje</ListItemText>
                        </MenuItem>

                        <MenuItem onClick={() => { props.handleFeedback(); handleCloseSettings(); }}>
                            <ListItemIcon>
                                <FeedbackIcon/>
                            </ListItemIcon>
                            <ListItemText>Lähetä palautetta</ListItemText>
                        </MenuItem>

                        <Divider />

                        <MenuItem onClick={() => { onLogout(); handleCloseSettings() }}>
                            <ListItemIcon>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText>Kirjaudu ulos</ListItemText>
                        </MenuItem>


                    </Menu>
                </Box>

            </Box>
        </Drawer>
        </>
    );
}