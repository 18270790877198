import React, { useState, useEffect } from "react";
import { 
    AdministrationUserApi, 
    AdministrationUserPasswordResetApi 
} from "../lib/apiActions";
import Alert from '@mui/material/Alert';
import {
    Box,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Button, 
    Typography, 
    TextField,
    Select,
    MenuItem,
    FormControl,
} from "@mui/material";

const sendPasswordReset = async (
        email: string, 
        fetchUsers?: any,
        emailRegex: any = /^[^\s@]+@[^\s@]+\.[^\s@]+$/, 
        send_email: boolean = true, 
        reason: string = "onboarding", 
    ) => {
    try {
        if (!emailRegex.test(email)) {
            return;
        }

        const body = {
            email: email,
            send_email: send_email,
            reason: reason,
        }
        await AdministrationUserPasswordResetApi.sendPasswordResetLink(body, null);
        fetchUsers?.();
    } catch (error) {
        // console.error('Error sending password reset:', error);
    }
}

enum userType {
    Lawyer = "Lawyer",
    Entrepreneur = "Entrepreneur",
}

interface CreateUserProps {
    fetchUsers: () => void;
    sendPasswordReset: any;
}

const CreateUser: React.FC<CreateUserProps> = ({ fetchUsers, sendPasswordReset }) => {
    const [email, setEmail] = useState<string>("");
    const [type, setType] = useState<userType>(userType.Entrepreneur);
    const organization = "Agora Legal Oy";
    const brand = "agora";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [isCreatingUser, setCreatingUser] = useState<boolean>(false);
    const [alert, setAlert] = useState<string | null>(null);

    const handleTypeChange = (e: any) => {
        setType(e.target.value);
        console.log("value", e.target.value);
    } 

    const createUserPress = async () => {
        setCreatingUser(true);
        try {
            if (!emailRegex.test(email)) {
                setAlert("Virheellinen sähköposti");
                return;
            }

            const body = {
                email: email,
                organization: organization,
                brand: brand,
                type: type,
                role_id: [],
            }
            await AdministrationUserApi.createUser(body, null);
            setAlert("Käyttäjä luotu: " + email + " " + type);
            fetchUsers();
        } catch (error) {
            // console.error('Error creating user:', error);
            setAlert("Virhe: " + error);
        } finally {
            setCreatingUser(false);
        }
    }

    return (
        <>
            <Box sx={{ 
                width: { xs: "100vw", sm: "70vw", },
                height: "initial", 
                mt: { xs: 3, sm: 5 }, 
            }}>
                <FormControl sx={{ 
                        display: "flex", 
                        flexDirection: { xs: "column", sm: "row" },
                    }}>
                    <TextField label="sähköposti" 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(event.target.value);
                    }}/>
                    <Select
                        value={type}
                        label="type"
                        onChange={handleTypeChange}
                        sx={{
                            width: { xs: "33vw", sm: "10vw", }
                        }}
                        >
                        <MenuItem value={"Lawyer"}>Lawyer</MenuItem>
                        <MenuItem value={"Entrepreneur"}>Entrepreneur</MenuItem>
                    </Select>
                    
                    <Button variant="contained" color="primary" onClick={createUserPress} sx={{ 
                        mt: { xs: 1, sm: 0 }, 
                        mr: { xs: 0, sm: 5 }, 
                        mb: { xs: 1, sm: 0 }, 
                        ml: { xs: 0, sm: 5 }, 
                        width: { xs: "50vw", sm: "initial" }, 
                    }}
                    >Luo käyttäjä</Button>

                    <Button variant="contained" color="primary" onClick={() => sendPasswordReset(email)} disabled={isCreatingUser} sx={{
                        width: { xs: "75vw", sm: "initial" },
                    }}
                    >Lähetä salasana linkki</Button>

                </FormControl>
                {alert && 
                    <Alert 
                        severity={alert.includes("Virhe") ? "error" : "success"} 
                        onClose={() => {setAlert(null);}}
                    >
                        {alert}
                    </Alert>
                }
            </Box>
        </>
    );
}

interface User {
    uuid: string;
    email: string;
    last_login: string;
    has_active_passwordresettoken: boolean;
}

interface ListUsersProps {
    users: User[] | null;
    sendPasswordReset: any;
    fetchUsers: any;
}

const ListUsers: React.FC<ListUsersProps> = ({ users, sendPasswordReset, fetchUsers }) => {

    return (
        <TableContainer sx={{ 
            minWidth: { xs: "100vw", sm: "60vw", }, 
            height: { xs: "initial", sm: "50vh", }, 
            overflow: "auto", 
        }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><Typography variant="subtitle1">Sähköposti</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">Lähetä salasanan resetointi uudelleen</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">Aktivoitu</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users && users.length > 0 ? (
                        users.map((user) => (
                            <TableRow key={user.uuid}>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>
                                    <Button variant="outlined" 
                                        disabled={user.has_active_passwordresettoken || user.last_login !== null}
                                        onClick={() => sendPasswordReset(user.email, fetchUsers)}
                                        >
                                            Lähetä uudelleen
                                    </Button>
                                </TableCell>
                                <TableCell>{user.last_login ? "Kyllä" : "Ei"}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={2} align="center">
                                <Typography variant="body2">No users available</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

interface StaffViewProps {
}

const StaffView: React.FC<StaffViewProps> = (props) => {
    const [users, setUsers] = useState<User[] | null>(null);

    const fetchUsers = async () => {
        try {
            const response = await AdministrationUserApi.get(null);
            if (response.status >= 300) {
                console.error('Error fetching users:', response);
                return;
            }
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <Box sx={{
            width: { xs: "100vw", sm: "80vw", },
            height: { xs: "100vh", sm: "70vh", },
            overflow: "auto", 
        }}>
            {/* unnecessary re-renders due to fetchUsers */}
            <CreateUser fetchUsers={fetchUsers} sendPasswordReset={sendPasswordReset}/>
            <ListUsers users={users} fetchUsers={fetchUsers} sendPasswordReset={sendPasswordReset}/>
        </Box>
    );
}

export default StaffView;