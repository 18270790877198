import React, { useState, useEffect, useRef } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import {
    TextField,
    Card,
    Paper,
    Box,
    Container,
    CircularProgress,
    useTheme,
    ListItemIcon,
    ListItemText,
    Hidden,
    Avatar,
    Button,
    Typography
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { SentMessage, ReceivedMessage } from './bubbles';
import { Message, RephraseMessage, Source, chatSelector, rephraseChat } from '../../lib/ducks/chatDuck';
import { WelcomeMessage } from '../welcomeMessage';
import { useAppSelector, useAppDispatch } from '../../lib/hook';
import { authSelector, SourceTable } from '../../lib/ducks/authDuck';
import { ChatApi, DocumentApi } from '../../lib/apiActions';
import { AlertDialog, InputDialog } from '../dialogs/dialogs';
import { text } from 'body-parser';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SourcesTables } from '../../lib/ducks/chatDuck'
import CheckIcon from '@mui/icons-material/Check';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getPipeColor } from '../../utils/pipeColor';
import { ProSidebarProvider } from 'react-pro-sidebar';
import OpenerButton from '../CustomerContact/OpenerButton';
import CustomerContactFormDialog from '../CustomerContact/CustomerContactFormDialog';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import useCheckMobileScreen from '../../utils/useCheckMobileScreen';
import { UserDocument } from "../../views/fileManagamentView";
import { useNavigate } from 'react-router-dom';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import VisuallyHiddenInput from '../VisuallyHiddenInput';

const PopupChat = (props: any) => {
    const { messages, handleAbort } = props
    const [textField, setTextField] = useState("");
    const user = useAppSelector(authSelector).user;
    const selectedChatId = useAppSelector(chatSelector).selectedChatId;
    const selectedPipe = useAppSelector(chatSelector).selectedPipe
    const theme = useTheme()
    const bottomRef = useRef<HTMLDivElement>(null);
    const lastRef = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedSources, setSelectedSources] = useState<SourceTable[]>([]);
    const [filteredSourceTables, setFilteredSourceTables] = useState<SourceTable[]>([]);
    const tableNames = user?.roles?.find(role => role.name === selectedPipe)?.source_tables || []; // table names based on selected pipe
    const [isFormOpen, setFormOpen] = useState(false);
    const isMobile = useCheckMobileScreen();
    const navigate = useNavigate();
    const [files, setFiles] = useState<UserDocument[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<UserDocument[]>([]);
    const [anchorElAttachFile, setAnchorElAttachFile] = React.useState<null | HTMLElement>(null);
    const openAttachFile = Boolean(anchorElAttachFile);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const handleSelectedDocument = (document: UserDocument) => {
        console.log("selected document: ", document);
        if (document === null) {
            setSelectedSources([]); // mikä ihme tää on?
            return;
        }
        if (selectedFiles.includes(document)) {
            setSelectedFiles(prev => {
                const updatedFiles = prev.filter(item => item !== document);
                return updatedFiles;
            });
        } else {
            setSelectedFiles(prev => {
                const updatedFiles = [...prev, document];
                return updatedFiles;
            });
        }
    }
    const handleClickAttachFile = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElAttachFile(event.currentTarget);
    }
    const handleMenuCloseAttachFile = () => {
        setAnchorElAttachFile(null);
    };
    const fetchDocuments = async () => {
        try {
            const response = await DocumentApi.getAll(null);
            if (response.status >= 300) {
                return;
            }
            console.log("documents api response: " + response.data);
            setFiles(response.data);
        } catch (error) {
            console.log("Error: " + error);
        }
    }

    useEffect(() => {
        fetchDocuments();
    }, []); // varmista tää peteltä

    const [anchorElDocumentQuery, setAnchorElDocumentQuery] = React.useState<null | HTMLElement>(null);
    const openDocumentQuery = Boolean(anchorElDocumentQuery);
    const handleClickDocumentQuery = (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>) => {
        // console.log("Clicked element:", event.currentTarget);
        setAnchorElDocumentQuery(event.currentTarget);
    }
    const handleCloseDocumentQuery = () => {
        setAnchorElDocumentQuery(null);
    }
    const handleSummarize = async (length: string) => { 
        const message = "Tiivistä";
        const file_id = selectedFiles.map(({ id }: UserDocument) => id);
        props.summarizeDocuments(file_id, length, message);
        setSelectedFiles([]);
        setTextField("");
    } 
    const handleReview = (message: string) => {
        const file_id = selectedFiles.map(({ id }: UserDocument) => id);
        props.reviewDocuments(file_id, message);
        setSelectedFiles([]);
        setTextField("");
    }

    const menuOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [showArrow, setShowArrow] = useState(false);

    const onScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setShowArrow(false);
            //console.log("bottom")
        } else {
            setShowArrow(true);
        }
    };

    const handleSelectedSources = (source: SourceTable | null) => {
        if (source === null) {
            setSelectedSources([]);
            return;
        }
        if (selectedSources.includes(source)) {
            setSelectedSources(prev => {
                const updatedSources = prev.filter(item => item !== source);
                return updatedSources;
            });
        } else {
            setSelectedSources(prev => {
                const updatedSources = [...prev, source];
                return updatedSources;
            });
        }
    };

    const scrollToBottom = () => {
        try {
            bottomRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        } catch (ex) {

        }
    };

    const handleRephrase = async (message: string, save_message: boolean) => {
        console.log("handleRephrase", message)
        if (props.isLoading || message.trim() === "") {
            setTextField("");
            return;
        }
        const selectedSourcesArray = selectedSources.map((source: SourceTable) => source.names).flat(1);
        //console.log("selectedSourcesArray", selectedSourcesArray)
        props.rephraseMessage(message, selectedSourcesArray);

        setTextField("");
    }

    const handleSend = async (text: string, deniedSources: string[]) => {
        console.log("handleSend", text)
        if (props.isLoading) {
            return;
        }

        const selectedSourcesArray = selectedSources.map((source: SourceTable) => source.names).flat(1);
        //console.log("selectedSourcesArray", selectedSourcesArray)
        props.sendMessage(text, deniedSources, selectedSourcesArray)
    }

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;
        try {
            const formData = new FormData();
            formData.append("file", file);
            await DocumentApi.create(formData, { headers: { "Content-Type": "multipart/form-data" } });
            fetchDocuments();
        } catch (error) {
            console.log("Error: " + error);
        } finally {
            setIsUploading(false);
        }
    }

    useEffect(() => {
        const newFilteredSourceTables = SourcesTables.filter(source =>
            Array.isArray(tableNames) && tableNames.includes(source.names[0])
        );
        setFilteredSourceTables(newFilteredSourceTables);
        setSelectedSources([]);
    }, [selectedPipe]);

    useEffect(() => {
        if (props.messages) {
            scrollToBottom();
        }
    }, [props.messages, lastRef.current?.lastElementChild]);

    return (
        <>

            {selectedChatId || messages?.length > 0 ?
                <Box
                    onScroll={onScroll}
                    sx={{
                        overflow: 'auto', overflowY: 'auto', display: 'flex', justifyContent: 'center', flex: 1, width: { xs: "100%", sm: undefined }, ml: { xs: "auto", sm: undefined }, 
                    }} >
                    <Box ref={lastRef}
                        sx={{
                            maxWidth: { xs: "100%", sm: '880px' }, paddingTop: 5, width: { xs: "70vw", sm: "100vw" }, height: '100%',  
                        }} >
                        {messages?.length === 0 &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center',
                                    minHeight: "80vh",
                                    width: "100vw",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        }

                        {messages?.map((message: Message, index: any) => {
                            if (message.role === 1) {
                                return (
                                    <SentMessage
                                        key={"message" + index}
                                        text={message.content}
                                        // document_names={selectedFiles.map(({ name }: UserDocument) => name)}
                                    />
                                )
                            }
                            if (message.role === 2) {
                                return (
                                    <ReceivedMessage
                                        key={"message" + index}
                                        message={message}
                                        sendMessage={handleSend}
                                        handleAbort={handleAbort}
                                        rephraseMessage={handleRephrase}
                                        lastMessage={messages.length == index + 1}
                                        handleSummarize={handleSummarize}
                                        document_names={selectedFiles.map(({ name }: UserDocument) => name)}
                                    />
                                )
                            }
                        })}
                        <Box ref={bottomRef} sx={{ minHeight: '105px', width: '100%', flexGrow: 1 }} onScroll={onScroll} />
                    </Box>
                </Box >

                :
                <WelcomeMessage userName={user?.email} mockChat={props.mockChat} selectedFiles={selectedFiles} />
            }


            <Box
                sx={{
                    marginLeft: '-8px',
                    paddingBottom: "32px", 
                    position: 'fixed',
                    bottom: 0,
                    maxWidth: '880px',
                    width: { xs: '100%', sm: 'calc(95% - 450px)' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    backgroundColor: { xs: "#FFFFFF", sm: "transparent" },
                }}
            >
                {selectedFiles.length > 0 && (
                        <Typography sx={{ fontSize: "14px", }}>
                            {selectedFiles.length} tiedostoa valittu
                        </Typography>
                    )}
                <Card
                    sx={{
                        //margin: 1,
                        //p: 3,
                        padding: 0,
                        //padding: "8px 12px 8px 12px",
                        border: "1px #E6E6E9",
                        borderRadius: "8px",
                        width: '100%',
                        maxWidth: { xs: "90vw", sm: '880px' },
                        maxHeight: { xs: "94px", sm: '40px' },
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        // backgroundColor: "grey.800", 
                        //backgroundColor: '#0e0e0e99',
                        backgroundColor: theme.palette.boxShade,
                        backdropFilter: 'blur(40px)',
                        mb: { xs: "-10px", sm: undefined }, 
                    }}
                >
                    <IconButton 
                        onClick={handleClickAttachFile}
                        sx={{
                            ml: { xs: undefined, sm: "5px", }, 
                        }}
                    >
                        <AttachFileIcon/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorElAttachFile}
                        open={openAttachFile}
                        onClose={handleMenuCloseAttachFile}
                    >
                        {files.map((file) => (
                            <MenuItem key={file.id} onClick={() => handleSelectedDocument(file)}>
                                <ListItemText>{file.name}</ListItemText>
                                {selectedFiles.includes(file) && (
                                    <ListItemIcon>
                                        <CheckIcon fontSize="small" />
                                    </ListItemIcon>
                                )}
                            </MenuItem>
                        ))}
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<FileUploadIcon />}
                            disabled={isUploading}
                            >
                            Lataa tiedosto
                            <VisuallyHiddenInput
                                accept=".pdf, .docx"
                                type="file"
                                onChange={(event) => handleFileUpload(event)}
                                multiple
                            />
                            </Button>
                    </Menu>
                    <TextField
                        helperText={selectedSources.length > 1 ? "Useat lähdevalinnat saattavat hidastaa vastausten saantia" : ""}
                        sx={{
                            width: { xs: "90vw", sm: "80vw" }, 
                            height: { xs: "94px", sm: "39px" },
                            maxHeight: { xs: "94px", sm: "39px" },
                            margin: { xs: "5px 0px 0px 10px", sm: undefined },
                            fontSize: "14px",
                            overflowY: { xs: "auto", sm: undefined },
                        }}
                        id="standard-basic"
                        variant="standard"
                        multiline
                        placeholder="Kirjoita viesti"
                        onChange={(event) => setTextField(event.target.value)}
                        value={textField}
                        disabled={props.isLoading}
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter' && !ev.shiftKey) {
                                ev.preventDefault();
                                selectedFiles.length === 0 
                                    ? handleRephrase(textField, true) 
                                    : handleClickDocumentQuery(ev);
                            }
                        }}
                        InputProps={{
                            disableUnderline: true, // <== added this
                        }}
                    />
                    <Box sx={{
                        width: { xs: "10vw", sm: undefined },
                        height: { xs: "94px", sm: undefined }, 
                        // backgroundColor: "blue",
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: { xs: "space-between", sm: undefined },
                        alignItems: { xs: "center", sm: undefined },
                    }}>
                        <Button
                            sx={{ 
                                p: { xs: "0px 2vw 1vh 0px", sm: 0 }, 
                                mt: { xs: "auto", sm: "initial" }, 
                                width: '24px', 
                                order: 2
                            }}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                selectedFiles.length === 0 
                                    ? handleRephrase(textField, false) 
                                    : handleClickDocumentQuery(event);
                            }}                            
                            //color="primary"
                            //color={getPipeColor(selectedPipe)}
                            //loading={props.isLoading}
                            disabled={props.isLoading}
                            endIcon={
                                <Avatar
                                    sx={{
                                        width: '24px', height: '24px',
                                        //bgcolor: (theme) => theme.palette.primary.main 
                                        bgcolor: getPipeColor(selectedPipe)
                                    }}
                                    color='primary'
                                >
                                    <SendIcon
                                        sx={{ width: '14px', height: '14px' }}
                                        fontSize='small'
                                    />
                                </Avatar>
                            }
                        />
                        <Menu
                            anchorEl={anchorElDocumentQuery}
                            open={openDocumentQuery}
                            onClose={handleCloseDocumentQuery}
                            >
                                <MenuItem onClick={() => {props.mockChat("Aloitetaan yhteenveto."); handleCloseDocumentQuery();}}>Yhteenveto</MenuItem>
                                <MenuItem onClick={() => {handleReview(textField); handleCloseDocumentQuery();}} disabled={textField.trim() === ""}>Tarkista</MenuItem>
                        </Menu>
                        {user?.type == "lawyer" &&
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={menuOpen ? 'long-menu' : undefined}
                                aria-expanded={menuOpen ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                sx={{
                                    ml: { xs: "1vw", sm: undefined },
                                    order: 1, 
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        }
                        {user?.type == "lawyer" &&
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => handleSelectedSources(null)}>
                                {selectedSources.length === 0 && (
                                    <ListItemIcon>
                                        <CheckIcon fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Oletuslähde</ListItemText>
                            </MenuItem>
                            {filteredSourceTables.map((source) => (
                                <MenuItem key={source.displayName} onClick={() => handleSelectedSources(source)}>
                                    {selectedSources.includes(source) && (
                                        <ListItemIcon>
                                            <CheckIcon fontSize="small" />
                                        </ListItemIcon>
                                    )}
                                    <ListItemText>{source.displayName}</ListItemText>
                                </MenuItem>
                            ))}
                        </Menu>
                        }
                    </Box>
                </Card>
                {showArrow && !isMobile && 
                    <IconButton sx={{ marginLeft: "calc(100% + 60px)", position: 'absolute' }} onClick={scrollToBottom}>
                        <ArrowDownwardIcon />
                    </IconButton>
                }
            </Box>
            {user?.type == "entrepreneur" && !isMobile &&
                <Box
                    sx={{
                        //marginLeft: '-8px',
                        marginBottom: '28px',
                        position: 'fixed',
                        right: '30px',
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        // backgroundColor: "grey.800",
                        backgroundColor: 'transparent',
                    }}>
                    <>
                        <Box>
                            <OpenerButton
                            onClick={() => setFormOpen(true)}
                            startIcon={<ContactPhoneIcon />}
                            sxStyle={{borderRadius: "100px"}}
                            style={{ variant: "contained", color: "primary"}}
                            />
                            <CustomerContactFormDialog
                            isOpen={isFormOpen}
                            handleClickClose={() => setFormOpen(false)}
                            user={user}
                            />
                        </Box>
                    </>
                </Box>
        }
        </>
    );
}



export default PopupChat;